import { EditOutlined, HeartOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Skeleton } from 'antd'
import { format } from 'date-fns'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  EKeyBoardCode,
  EUserGender,
  GENDER_TYPE,
  INITIAL_PAGINATION_SiZE,
  PATH_USER_MANAGEMENT,
  SICKNESS_OPTIONS,
} from '@configs'
import {
  RootState,
  getAllUsersAction,
  selectUsersLoading,
  useAppDispatch,
} from '@redux'
import { Button, Input, SharedTable } from 'src/common'
import { mapSickness } from '@utils'

type Props = {}

export const UserManagementPage = (props: Props) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')

  const dispatch = useAppDispatch()
  const getAllUsersActionLoading = useSelector((state: RootState) =>
    selectUsersLoading(state, 'getAllUsersAction')
  )
  const { users, usersCurrentPage, usersTotalItems } = useSelector(
    (state: RootState) => state.users
  )

  const getAllUsers = () => {
    if (search) {
      const parsedQuery = queryString.parse(search)
      dispatch(getAllUsersAction(parsedQuery))
      return
    }

    dispatch(getAllUsersAction())
  }

  const onSearchUsers = (page?: number) => {
    const localURlQuery: any = {
      page: page ?? 1,
      size: INITIAL_PAGINATION_SiZE,
    }
    if (searchValue) {
      localURlQuery.search = searchValue
    }

    const stringifyQuery = queryString.stringify(localURlQuery)
    setSearchParams(stringifyQuery)
  }

  const mapGender = (gender: any) => {
    return GENDER_TYPE[gender]?.label || gender
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: '_id',
    },
    {
      title: '名前',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '性別',
      dataIndex: 'gender',
      key: 'gender',
      render: (e: any) => {
        return <>{mapGender(e)}</>
      },
    },
    {
      title: '主病名*',
      dataIndex: 'sickness',
      key: 'sickness',
      render: (e: any) => {
        return <span className="capitalize">{mapSickness(e).join(', ')}</span>
      },
    },
    {
      title: '作成日時',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (e: any) => {
        return <>{format(new Date(e), 'dd/MM/yyyy HH:mm:ss')}</>
      },
    },
    {
      title: 'ステータス',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (e: any) => {
        return <>{e ? '有効' : '無効'}</>
      },
    },
    {
      title: 'アクション',
      key: 'action',
      dataIndex: '_id',
      render: (id: any) => (
        <div className="flex space-x-4">
          <EditOutlined
            className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
            onClick={() => {
              navigate(`${PATH_USER_MANAGEMENT}/edit/${id}`)
            }}
          />
          <HeartOutlined
            className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
            onClick={() => {
              navigate(`${PATH_USER_MANAGEMENT}/view-health-detail/${id}`)
            }}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    getAllUsers()
  }, [dispatch, search])

  return (
    <Card>
      <div className="sm:pl-[0.75rem] sm:pr-[0.5rem] sm:max-w-[300px] flex items-center sm:justify-between flex-col sm:flex-row">
        <Input
          placeholder="メール、名前を入力"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === `${EKeyBoardCode.ENTER}`) {
              onSearchUsers()
            }
          }}
          prefix={
            <span className="flex items-center justify-center pl-[0.75rem]">
              <SearchOutlined />
            </span>
          }
        />

        <Button
          size="small"
          type="primary"
          style={{
            minWidth: '4rem',
          }}
          className="ml-1"
          onClick={() => {
            onSearchUsers()
          }}
        >
          検索
        </Button>
      </div>
      {getAllUsersActionLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <SharedTable
          columns={columns}
          dataSource={users?.map((user, index) => {
            return {
              ...user,
              key: user._id,
              no: index + 1,
              sickness: user?.userData?.sickness ?? [],
              gender: user?.userData?.gender,
            }
          })}
          paginationProps={{
            total: !!usersTotalItems ? +usersTotalItems : undefined,
            pageSize: INITIAL_PAGINATION_SiZE,
            current: +usersCurrentPage,
            onChange(page) {
              onSearchUsers(page)
            },
          }}
        />
      )}
    </Card>
  )
}

import {
  IFetchArticlesParams,
  IFetchArticlesSuccessData,
  IArticleDetail,
  TUpdateArticleData,
  IArticleData,
  ICreateArticleSuccessData,
  // TUpdateArticleData,
} from '@interfaces'
import { ApiClient } from './axiosClient'

export const articleManagementAPI = {
  getAllArticles: async (params?: IFetchArticlesParams) => {
    return await ApiClient.get<IFetchArticlesSuccessData>('/articles', {
      params,
    })
  },

  getArticleById: async (id: string) => {
    return await ApiClient.get<IArticleDetail>(`/articles/${id}`)
  },

  createArticle: async (payload: IArticleData) => {
    return await ApiClient.post<ICreateArticleSuccessData, IArticleData>(
      '/articles',
      payload
    )
  },

  updateArticleById: async (payload: Partial<TUpdateArticleData>) => {
    const { _id, ...passPayload } = payload
    return await ApiClient.patch<
      IArticleDetail,
      Omit<TUpdateArticleData, '_id'>
    >(`/articles/${_id}`, passPayload)
  },
}

import { Card, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RouterParams } from '@interfaces'
import {
  RootState,
  getUserByIdAction,
  selectUsersLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { SharedTable } from 'src/common'
import { INITIAL_PAGINATION_SiZE } from '@configs'

export const UserHealthDetail = () => {
  const { userId } = useParams<RouterParams['UserHealthDetail']>()
  const { users } = useAppSelector((state: RootState) => state.users)

  const [page, setPage] = useState(1)

  const dispatch = useAppDispatch()
  const getUserByIdActionLoading = useSelector((state: RootState) =>
    selectUsersLoading(state, 'getUserByIdAction')
  )

  const { user: selectedUser } = useSelector((state: RootState) => state.users)

  const getUserById = () => {
    const id = userId
    id && dispatch(getUserByIdAction(id))
  }

  const columns = [
    {
      title: '日付',
      dataIndex: 'date',
      key: 'date',
      render: (e: string) => {
        return <>{format(new Date(e), 'M/d')}</>
      },
    },
    {
      title: '脈拍上限',
      dataIndex: 'pulseAverage',
      key: 'pulseAverage',
    },
    {
      title: '脈拍下限',
      dataIndex: 'trainingTime',
      key: 'trainingTime',
    },
    {
      title: 'ステップ数',
      dataIndex: 'stepCount',
      key: 'stepCount',
    },
  ]

  useEffect(() => {
    getUserById()
  }, [dispatch])

  return (
    <Card>
      {getUserByIdActionLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <SharedTable
          columns={columns}
          dataSource={selectedUser?.userReports
            ?.slice((page - 1) * INITIAL_PAGINATION_SiZE, page * INITIAL_PAGINATION_SiZE)
            ?.map((report, index) => {
              return {
                ...report,
                key: report._id,
                pulseAverage: `${report.maxRate}/${report.minRate}(${report.avgRate})`,
              }
            })}
          paginationProps={{
            total: selectedUser?.userReports?.length
              ? selectedUser?.userReports?.length
              : undefined,
            pageSize: INITIAL_PAGINATION_SiZE,
            showSizeChanger: false,
            current: +page,
            onChange(page) {
              setPage(page)
            },
          }}
        />
      )}
    </Card>
  )
}

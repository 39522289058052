import { createSlice } from '@reduxjs/toolkit'

import { IArticleDetail } from '@interfaces'
import { RootState } from '.'
import {
  createArticleAction,
  getAllArticlesAction /*getArticleByIdAction, updateArticleByIdAction*/,
} from '../actions'
interface IArticlesState {
  articles: IArticleDetail[] | null
  article: IArticleDetail | null
  articlesCurrentPage: string | number
  articlesTotalPage: string | number
  articlesTotalItems: string | number
  selectedArticle: IArticleDetail | null

  loadings: Record<string, boolean | undefined>
}

const initialState: IArticlesState = {
  articles: [],
  article: null,
  articlesCurrentPage: 0,
  articlesTotalPage: 0,
  articlesTotalItems: 0,
  selectedArticle: null,
  loadings: {},
}

const articlesSlice = createSlice({
  name: 'articles',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllArticlesAction.pending, (state) => {
      state.loadings[`getAllArticlesActionLoading`] = true
    })
    builder.addCase(getAllArticlesAction.fulfilled, (state, action) => {
      state.loadings[`getAllArticlesActionLoading`] = false
      state.articles = action.payload?.result ?? []
      state.articlesCurrentPage = action.payload?.currentPage ?? 0
      state.articlesTotalPage = action.payload?.totalPage ?? 0
      state.articlesTotalItems = action.payload?.totalItems ?? 0
    })
    builder.addCase(getAllArticlesAction.rejected, (state) => {
      state.loadings[`getAllArticlesActionLoading`] = false
    })
    builder.addCase(createArticleAction.pending, (state) => {
      state.loadings[`createArticleActionLoading`] = true
    })
    builder.addCase(createArticleAction.fulfilled, (state) => {
      state.loadings[`createArticleActionLoading`] = false
    })
    builder.addCase(createArticleAction.rejected, (state) => {
      state.loadings[`createArticleActionLoading`] = false
    })
    // builder.addCase(getArticleByIdAction.pending, (state) => {
    //   state.loadings[`getArticleByIdActionLoading`] = true
    // })
    // builder.addCase(getArticleByIdAction.fulfilled, (state, action) => {
    //   state.loadings[`getArticleByIdActionLoading`] = false
    //   state.article = action.payload ?? {}
    // })
    // builder.addCase(getArticleByIdAction.rejected, (state) => {
    //   state.loadings[`getArticleByIdActionLoading`] = false
    // })
    // builder.addCase(updateArticleByIdAction.pending, (state) => {
    //   state.loadings[`updateArticleByIdActionLoading`] = true
    // })
    // builder.addCase(updateArticleByIdAction.fulfilled, (state, action) => {
    //   state.loadings[`updateArticleByIdActionLoading`] = false
    //   state.selectedArticle = action.payload
    // })
    // builder.addCase(updateArticleByIdAction.rejected, (state) => {
    //   state.loadings[`updateArticleByIdActionLoading`] = false
    // })
  },
})

export const articlesActions = {
  ...articlesSlice.actions,
}

export const selectArticlesLoading = (state: RootState, name: string) => {
  return state.plans.loadings[`${name}Loading`]
}
  

export default articlesSlice.reducer

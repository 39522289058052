import { yupResolver } from '@hookform/resolvers/yup'
import { Card, message } from 'antd'

import i18next from 'i18next'
import React, { useLayoutEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { EBoolean, EUserGender, GENDER_TYPE, SICKNESS_OPTIONS } from '@configs'
import { BaseResponseError, IEditUserData, RouterParams } from '@interfaces'
import {
  RootState,
  updateUserByIdAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Button, Input, SwitchButton, Text, TextArea } from 'src/common'
import { ShareSelectInput } from '../../components'

export const UserDetailPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'user', 'error'])

  const { userId } = useParams<RouterParams['UserDetailPage']>()
  const dispatch = useAppDispatch()
  const { users } = useAppSelector((state: RootState) => state.users)
  const updateUserByIdActionLoading = useAppSelector(
    (state: RootState) => state.users.loadings['updateUserByIdActionLoading']
  )

  const data = SICKNESS_OPTIONS.map((item, index) => ({
    label: item,
    value: index + 1,
  }))

  const selectedUsers =
    users && users.length
      ? users?.find((user) => user._id === userId)
      : undefined

  const schema = yup.object().shape({
    name: yup.string().required(i18next.t('error:field_required')),
    birthday: yup.string().required(i18next.t('error:field_required')),
    gender: yup.string().required(i18next.t('error:field_required')),
    sickness: yup
      .array()
      .of(yup.number())
      .min(1, i18next.t('error:field_required'))
      .required(i18next.t('error:field_required')),
    email: yup
      .string()
      .email(i18next.t('error:email_format_error')),
    betaBlocker: yup.boolean().required(i18next.t('error:field_required')),
    paceMaker: yup.boolean(),
    note: yup.string(),
    atrialFibrillation: yup.boolean(),
    highBloodPressure: yup.boolean(),
    dyslipidemia: yup.boolean(),
    diabetes: yup.boolean(),
    chronicKidneyDisease: yup.boolean(),
    pacingPulse: yup.string(),
    vtPulse: yup.string(),
    remarks: yup.string(),
    isActive: yup.boolean(),
  })

  const { control, handleSubmit } = useForm<IEditUserData>({
    defaultValues: {
      name: selectedUsers?.name,
      birthday: selectedUsers?.userData?.birthday,
      gender: selectedUsers?.userData?.gender,
      sickness: selectedUsers?.userData?.sickness ?? [],
      email: selectedUsers?.email,
      betaBlocker: selectedUsers?.userData?.betaBlocker,
      paceMaker: selectedUsers?.userData?.paceMaker,
      note: selectedUsers?.userData?.note,
      atrialFibrillation: selectedUsers?.userData?.atrialFibrillation,
      highBloodPressure: selectedUsers?.userData?.highBloodPressure,
      dyslipidemia: selectedUsers?.userData?.dyslipidemia,
      diabetes: selectedUsers?.userData?.diabetes,
      chronicKidneyDisease: selectedUsers?.userData?.chronicKidneyDisease,
      pacingPulse: selectedUsers?.userData?.pacingPulse,
      vtPulse: selectedUsers?.userData?.vtPulse,
      remarks: selectedUsers?.userData?.remarks,
      isActive: selectedUsers?.isActive,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const handleClickAction = handleSubmit(async (data) => {
    try {
      await dispatch(
        updateUserByIdAction({
          _id: selectedUsers?._id ?? '',
          email: data?.email,
          name: data?.name,
          isActive: data.isActive,
          userData: {
            birthday: data.birthday,
            gender: data.gender ? +data.gender : EUserGender.MALE,
            atrialFibrillation: data.atrialFibrillation,
            highBloodPressure: data.highBloodPressure,
            dyslipidemia: data.dyslipidemia,
            diabetes: data.diabetes,
            chronicKidneyDisease: data.chronicKidneyDisease,
            sickness: data.sickness ?? [],
            betaBlocker: data.betaBlocker,
            paceMaker: data.paceMaker,
            note: data.note,
            pacingPulse: data.pacingPulse ? +data.pacingPulse : 0,
            vtPulse: data.vtPulse ? +data.vtPulse : 0,
            remarks: data.remarks || '',
          },
        })
      ).unwrap()
      navigate(-1)
      message.success({
        content: 'ユーザーを更新しました',
      })
    } catch (err) {
      const error = err as BaseResponseError
      if (error) {
        message.error({
          content: error?.message,
        })
      }
    }
  })

  useLayoutEffect(() => {
    if (!userId) {
      navigate('/404')
    }
  }, [userId])

  return (
    <Card>
      {selectedUsers ? (
        <div>
          <div className="flex justify-between max:[640px]:flex-col">
            <div className="flex-1 sm:flex-[0_0_30%] sm:max-w-[30%] block relative min-h-[1px]">
              <div className="mb-4">
                <Controller
                  name="name"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        alignment="col"
                        label={t('common:name')}
                        name="name"
                        className="input"
                        value={value}
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value)
                        }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="birthday"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      alignment="col"
                      label={t('common:birthday')}
                      name="birthday"
                      className="input"
                      value={value}
                      type="date"
                      onChangeDate={(value) => {
                        onChange(value)
                      }}
                      errors={error?.message}
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="gender"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={GENDER_TYPE}
                        onChange={onChange}
                        label={t('common:gender')}
                        errors={error?.message}
                        value={value}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        alignment="col"
                        label={t('common:email')}
                        name="email"
                        className="input"
                        value={value}
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value)
                        }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="sickness"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        mode="multiple"
                        data={data}
                        onChange={onChange}
                        label={t('user:sickness')}
                        errors={error?.message}
                        defaultValue={value}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="isActive"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <div
                          style={{
                            marginBottom: '0.8rem',
                          }}
                        >
                          <Text>{t('user:status')}</Text>
                        </div>
                        <SwitchButton
                          size="small"
                          checked={value}
                          onChange={(e) => {
                            onChange(e)
                          }}
                        />
                      </>
                    )
                  }}
                />
              </div>
            </div>
            <div className="flex-1 sm:flex-[0_0_30%] sm:max-w-[30%] block relative min-h-[1px]">
              <div className="mb-4">
                <Controller
                  name="atrialFibrillation"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:atrial_fibrillation')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="highBloodPressure"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:high_blood_pressure')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="dyslipidemia"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:dyslipidemia')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="diabetes"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:diabetes')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="chronicKidneyDisease"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:chronic_kidney_disease')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="remarks"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        alignment="col"
                        label={t('common:other_diseases')}
                        name="remark"
                        className="input"
                        value={value}
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value)
                        }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
            </div>
            <div className="flex-1 sm:flex-[0_0_30%] sm:max-w-[30%] block relative min-h-[1px]">
              <div className="mb-4">
                <Controller
                  name="betaBlocker"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:beta_blocker')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="paceMaker"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        data={[
                          {
                            label: 'あり',
                            value: EBoolean.TRUE,
                          },
                          {
                            label: 'なし',
                            value: EBoolean.FALSE,
                          },
                        ]}
                        onChange={onChange}
                        label={t('common:pace_maker_icd')}
                        errors={error?.message}
                        value={value ? EBoolean.TRUE : EBoolean.FALSE}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="pacingPulse"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        alignment="col"
                        label={t('common:pacing_pulse')}
                        name="pacingPulse"
                        className="input"
                        value={value}
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value)
                        }}
                        errors={error?.message}
                        suffix={<div>回/分</div>}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="note"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextArea
                        label={t('common:note')}
                        name="note"
                        className="input"
                        value={value}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          onChange(e.target.value)
                        }}
                        errors={error?.message}
                        rows={4}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end w-full">
            <Button
              type="primary"
              className="mr-5"
              onClick={handleClickAction}
              loading={updateUserByIdActionLoading}
            >
              保存
            </Button>
            <Button
              type="ghost"
              onClick={() => {
                navigate(-1)
              }}
            >
              キャンセル
            </Button>
          </div>
        </div>
      ) : (
        <>ユーザーが見つかりませんでした</>
      )}
    </Card>
  )
}

import { Breadcrumbs } from '@components'
import { isNum, LogApp } from '@utils'
import { useLocation } from 'react-router-dom'

export const BreadcrumbsModule = () => {
  const location = useLocation()
  LogApp('location', location)
  const currentPath = location?.pathname
  const listPaths = currentPath
    .slice(1)
    .split('/')
    .filter((value: string) => !isNum(value)) //remove number in path

  const breadcrumbData = listPaths.reduce(
    (prev: any, value: string, index: number) => {
      LogApp('check0', prev, prev[index - 1])
      let labelJP = value
      switch (value) {
        case '':
          labelJP = 'ダッシュボード'
          break
        case 'user-management':
          labelJP = 'ユーザー管理'
          break
        case 'article-management':
          labelJP = '記事管理'
          break
        case 'edit':
          labelJP = '編集'
          break
        case 'view-health-detail':
          labelJP = 'ヘルス詳細を見る'
          break
        case 'notification':
          labelJP = '通知'
          break
        case 'change-password':
          labelJP = 'パスワード変更'
          break
        default:
          break
      }
      const path = prev[index - 1]?.path ? currentPath : '/' + value
      return [...prev, { label: labelJP, path: path }]
    },
    []
  )
  LogApp('location2', breadcrumbData)
  return <Breadcrumbs data={breadcrumbData} />
}

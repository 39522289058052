/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, Divider } from 'antd'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as z from 'zod'

import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { IArticleData, IArticleDetail } from '@interfaces'

interface IAddUpdateArticleManagementModalProps {
  selectedArticle?: IArticleDetail
  open: boolean
  isLoading: boolean
  onSelectArticleId?: (id: number) => void
  onClose?: () => void
  onSubmit: (data: IArticleData) => Promise<void>
}

const articleSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    }),
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    }),
})

const AddUpdateArticleManagementModal = (
  props: IAddUpdateArticleManagementModalProps
) => {
  const {
    open,
    selectedArticle,
    isLoading,
    onSelectArticleId,
    onClose,
    onSubmit,
  } = props
  console.log(isLoading)
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: '',
      content: '',
      isActive: true,
    },
    resolver: zodResolver(articleSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const isEdit = !!selectedArticle?._id

  const renderTitle = (title: string) => {
    const modifiedTitle = title.replace(/-/g, ' ')
    return modifiedTitle
  }

  useEffect(() => {
    if (!!selectedArticle && isEdit) {
      setValue('title', selectedArticle.title || '')
      setValue('content', selectedArticle.content || '')
    }
  }, [])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {isEdit ? '記事を編集する' : '新しい記事を追加'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="タイトル"
                    name="title"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
          <div>
            <Controller
              name="content"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="コンテンツ"
                    name="content"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="large"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              キャンセル
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="submit__btn login-btn"
              loading={isLoading}
            >
              保存
            </Button>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

const CheckBoxStyled = styled.div`
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => {
          return theme.colors?.primary
        }};
      }
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => {
      return theme.colors?.primary
    }};
  }

  .ant-checkbox-checked {
    :after {
      border-color: ${({ theme }) => {
        return theme.colors?.primary
      }};
    }

    .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors?.primary};
      background-color: ${({ theme }) => {
        return theme.colors?.primary
      }};
    }
  }
`

export default AddUpdateArticleManagementModal

import common from './common.json';
import dashboard from './dashboard.json';
import login from './login.json';
import error from './error.json';

export const fr = {
  common,
  dashboard,
  login,
  error,
};

import common from './common.json'
import login from './login.json'
import dashboard from './dashboard.json'
import error from './error.json'
import plan from './plan.json'
import user from './user.json'
import article from './article.json'
export const en = {
  common,
  login,
  dashboard,
  error,
  plan,
  user,
  article,
}

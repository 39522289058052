import {
  IFetchUsersParams,
  IFetchUsersSuccessData,
  IUserDetail,
  TUpdateUserData,
} from '@interfaces'
import { ApiClient } from './axiosClient'

export const userManagementAPI = {
  getAllUsers: async (params?: IFetchUsersParams) => {
    return await ApiClient.get<IFetchUsersSuccessData>('/users', {
      params,
    })
  },

  getUserById: async (id: string) => {
    return await ApiClient.get<IUserDetail>(`/users/${id}`)
  },

  updateUserById: async (payload: Partial<TUpdateUserData>) => {
    const { _id, ...passPayload } = payload
    return await ApiClient.patch<IUserDetail, Omit<TUpdateUserData, '_id'>>(
      `/users/${_id}`,
      passPayload
    )
  },
}

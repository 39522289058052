import {
  EditOutlined,
  HeartOutlined,
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Card, Skeleton, message } from 'antd'
import { format } from 'date-fns'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  EKeyBoardCode,
  GENDER_TYPE,
  INITIAL_PAGINATION_SiZE,
  PATH_ARTICLE_MANAGEMENT,
  SICKNESS_OPTIONS,
} from '@configs'
import {
  RootState,
  articlesActions,
  createArticleAction,
  getAllArticlesAction,
  selectArticlesLoading,
  useAppDispatch,
} from '@redux'
import { Button, Input, SharedTable } from 'src/common'
import { mapSickness } from '@utils'
import AddUpdateArticleManagementModal from './AddUpdateArticleManagementModal'
import { IArticleData } from '@interfaces'

type Props = {}

export const ArticleManagementPage = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const navigate = useNavigate()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')

  const dispatch = useAppDispatch()
  const getAllArticlesActionLoading = useSelector((state: RootState) =>
    selectArticlesLoading(state, 'getAllArticlesAction')
  )
  const { articles, articlesCurrentPage, articlesTotalItems } = useSelector(
    (state: RootState) => state.articles
  )

  const getAllArticles = () => {
    if (search) {
      const parsedQuery = queryString.parse(search)
      dispatch(getAllArticlesAction(parsedQuery))
      return
    }
    dispatch(getAllArticlesAction())
  }

  const onSearchArticles = (page?: number) => {
    const localURlQuery: any = {
      page: page ?? 1,
      size: INITIAL_PAGINATION_SiZE,
    }
    if (searchValue) {
      localURlQuery.search = searchValue
    }

    const stringifyQuery = queryString.stringify(localURlQuery)
    setSearchParams(stringifyQuery)
  }

  const createArticleActionLoading = useSelector(
    (state: RootState) => state.articles.loadings.createArticleActionLoading
  )

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: '_id',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'コンテンツ',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: '作成日時',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (e: any) => {
        return <>{format(new Date(e), 'dd/MM/yyyy HH:mm:ss')}</>
      },
    },
    {
      title: 'アクション',
      key: 'action',
      dataIndex: '_id',
      render: (id: any) => (
        <div className="flex space-x-4">
          <EyeOutlined
            className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
            onClick={() => {
              navigate(`${PATH_ARTICLE_MANAGEMENT}/edit/${id}`)
            }}
          />
        </div>
      ),
    },
  ]

  const onCreateArticle = async (data: IArticleData) => {
    try {
      let response: any = await dispatch(
        createArticleAction({
          title: data.title,
          content: data.content,
        })
      ).unwrap()
      if (response.success) {
        message.success({
          content: '記事が正常に作成されました',
        })
        getAllArticles()
        onClose()
      }
    } catch (error: any) {
      if (error) {
        message.error({
          content: error.errors?.length ? error.errors[0] : error.message,
        })
      }
    }
  }

  const onClose = () => {
    setOpen(false)
    // dispatch(articlesActions.setSelectedArticle())
  }

  useEffect(() => {
    getAllArticles()
  }, [dispatch, search])

  return (
    <>
      {open && (
        <AddUpdateArticleManagementModal
          open={open}
          isLoading={!!createArticleActionLoading}
          onClose={onClose}
          onSubmit={onCreateArticle}
        />
      )}
      <Card>
        <div className="sm:pl-[0.75rem] sm:pr-[0.5rem] flex items-center justify-between sm:justify-between flex-col sm:flex-row">
          <div className="flex items-center">
            <Input
              placeholder="メール、名前を入力"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === `${EKeyBoardCode.ENTER}`) {
                  onSearchArticles()
                }
              }}
              prefix={
                <span className="flex items-center justify-center pl-[0.75rem]">
                  <SearchOutlined />
                </span>
              }
            />

            <Button
              size="small"
              type="primary"
              style={{
                minWidth: '4rem',
              }}
              className="ml-1"
              onClick={() => {
                onSearchArticles()
              }}
            >
              検索
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setOpen(true)
              }}
            >
              新しく追加する
            </Button>
          </div>
        </div>
        {getAllArticlesActionLoading ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <SharedTable
            columns={columns}
            dataSource={
              articles &&
              articles?.map((article, index) => {
                return {
                  ...article,
                  key: article._id,
                  no: index + 1,
                  title: article?.title,
                  content:
                    article && article?.content.length > 300
                      ? article.content.slice(0, 300) + '...'
                      : article.content,
                }
              })
            }
            paginationProps={{
              total: !!articlesTotalItems ? +articlesTotalItems : undefined,
              pageSize: INITIAL_PAGINATION_SiZE,
              current: +articlesCurrentPage,
              onChange(page) {
                onSearchArticles(page)
              },
            }}
          />
        )}
      </Card>
    </>
  )
}

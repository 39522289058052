import { createAsyncThunk } from '@reduxjs/toolkit'

import { articleManagementAPI } from '@api'
import { INITIAL_PAGINATION_SiZE } from '@configs'
import { IFetchArticlesParams, /* TUpdateArticleData */ 
TUpdateArticleData} from '@interfaces'

export const getAllArticlesAction = createAsyncThunk(
  'articles/getAllArticlesAction',
  async (params: IFetchArticlesParams | undefined) => {
    try {
      const localParams = params
        ? params
        : {
            page: 1,
            size: INITIAL_PAGINATION_SiZE,
          }
      const res = await articleManagementAPI.getAllArticles(localParams)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getArticleByIdAction = createAsyncThunk(
  'articles/getArticleByIdAction',
  async (id: string) => {
    try {
      const res = await articleManagementAPI.getArticleById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createArticleAction = createAsyncThunk(
  'articles/createArticleAction',
  async (payload: Partial<TUpdateArticleData>, { fulfillWithValue }) => {
    const res = await articleManagementAPI.createArticle(payload)
    return fulfillWithValue(res)
  }
)

export const updateArticleByIdAction = createAsyncThunk(
  'articles/updateArticleByIdAction',
  async (payload: Partial<TUpdateArticleData>, { fulfillWithValue }) => {
    const res = await articleManagementAPI.updateArticleById(payload)
    return fulfillWithValue(res.data)
  }
)

import { yupResolver } from '@hookform/resolvers/yup'
import { Card, message } from 'antd'

import i18next from 'i18next'
import React, { useLayoutEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { EBoolean, GENDER_TYPE, SICKNESS_OPTIONS } from '@configs'
import { BaseResponseError, IEditArticleData, RouterParams } from '@interfaces'
import {
  RootState,
  updateArticleByIdAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Button, Input, SwitchButton, Text, TextArea } from 'src/common'
import { ShareSelectInput } from '../../components'

export const ArticleDetailPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['article', 'error'])

  const { articleId } = useParams<RouterParams['ArticleDetailPage']>()
  const dispatch = useAppDispatch()
  const { articles } = useAppSelector((state: RootState) => state.articles)
  const updateArticleByIdActionLoading = useAppSelector(
    (state: RootState) =>
      state.articles.loadings['updateArticleByIdActionLoading']
  )

  const data = SICKNESS_OPTIONS.map((item, index) => ({
    label: item,
    value: index + 1,
  }))

  const selectedArticles =
    articles && articles.length
      ? articles?.find((article) => article._id === articleId)
      : undefined

  const schema = yup.object().shape({
    title: yup.string().required(i18next.t('error:field_required')),
    content: yup.string().required(i18next.t('error:field_required')),
  })

  const { control, handleSubmit } = useForm<IEditArticleData>({
    defaultValues: {
      title: selectedArticles?.title,
      content: selectedArticles?.content,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  // const handleClickAction = handleSubmit(async (data) => {
  //   try {
  //     await dispatch(
  //       updateArticleByIdAction({
  //         _id: selectedArticles?._id ?? '',
  //         title: data?.title,
  //         content: data?.content,
  //       })
  //     ).unwrap()
  //     navigate(-1)
  //     message.success({
  //       content: 'ユーザーを更新しました',
  //     })
  //   } catch (err) {
  //     const error = err as BaseResponseError
  //     if (error) {
  //       message.error({
  //         content: error?.message,
  //       })
  //     }
  //   }
  // })

  useLayoutEffect(() => {
    if (!articleId) {
      navigate('/404')
    }
  }, [articleId])

  return (
    <Card>
      {selectedArticles ? (
        <div>
          <div className="flex justify-between max:[640px]:flex-col">
            <div className="flex-1 sm:flex-[0_0_100%] sm:max-w-[100%] block relative min-h-[1px]">
              <div className="mb-4">
              <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        alignment="col"
                        label={t('article:title')}
                        name="title"
                        className="input"
                        value={value}
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value)
                        }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="content"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextArea
                        // alignment="col"
                        label={t('article:content')}
                        name="content"
                        className="input"
                        value={value}
                        rows={6}
                        // type="text"
                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        //   onChange(e.target.value)
                        // }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="flex items-center justify-end w-full">
            <Button
              type="primary"
              className="mr-5"
              onClick={handleClickAction}
              loading={updateArticleByIdActionLoading}
            >
              保存
            </Button>
            <Button
              type="ghost"
              onClick={() => {
                navigate(-1)
              }}
            >
              キャンセル
            </Button>
          </div> */}
        </div>
      ) : (
        <>記事が見つかりません</>
      )}
    </Card>
  )
}

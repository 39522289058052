import { IRoute } from '@interfaces'
import {
  ChangePasswordPage,
  Dashboard,
  ForgotPasswordPage,
  LoginPage,
  NotificationEditPage,
  NotificationPage,
  ResetPasswordPage,
  UserDetailPage,
  UserHealthDetail,
  UserManagementPage
} from '@pages'
import { ArticleManagementPage } from 'src/pages/article-management'
import { ArticleDetailPage } from 'src/pages/article-management/ArticleDetailPage'
import {
  PATH_ARTICLE_MANAGEMENT,
  PATH_CHANGE_PASSWORD,
  PATH_DASHBOARD,
  PATH_EDIT_ARTICLE,
  PATH_EDIT_NOTIFICATION,
  PATH_EDIT_USER,
  PATH_FORGOT_PASSWORD,
  PATH_LOGIN,
  PATH_NOTIFICATION,
  PATH_RESET_PASSWORD,
  PATH_USER_HEALTH_DETAIL,
  PATH_USER_MANAGEMENT
} from './navigation'
export const routes: Array<IRoute> = [
  { path: PATH_RESET_PASSWORD, element: ResetPasswordPage },
  { path: PATH_CHANGE_PASSWORD, element: ChangePasswordPage },
  // { path: PATH_DETAIL_PLAN, element: PlanDetail },
  // { path: PATH_PLAN, element: PlanManagement },
  { path: PATH_DASHBOARD, element: Dashboard },
  { path: PATH_USER_MANAGEMENT, element: UserManagementPage },
  { path: PATH_EDIT_USER, element: UserDetailPage },
  { path: PATH_USER_HEALTH_DETAIL, element: UserHealthDetail },
  { path: PATH_NOTIFICATION, element: NotificationPage },
  { path: PATH_EDIT_NOTIFICATION, element: NotificationEditPage },
  { path: PATH_ARTICLE_MANAGEMENT, element: ArticleManagementPage },
  { path: PATH_EDIT_ARTICLE, element: ArticleDetailPage },

]

export const publicRoutes: Array<IRoute> = [
  //auth
]

export const restrictedRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, element: LoginPage },
  { path: PATH_FORGOT_PASSWORD, element: ForgotPasswordPage },
]
